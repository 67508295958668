import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import { GetURL } from "../Common/site/functions";
import ContactForm from "../Forms/contact-form";
import EnquiryForm from "../Forms/enquiry-form";
import ModalTiny from "react-bootstrap/Modal"
import _ from "lodash"
import $ from "jquery"

// import '../Header/Header.scss';
const StickyFooter = ({ page, lazyLoad, path, bannerInView }) => {

  const [modalContactformOpen, setContactformOpen] = React.useState(false);
  const [modalEnquiryFormOpen, setEnquiryFormOpen] = React.useState(false);
  const [modalFormTitle, setModalFormTitle] = React.useState("General Enquiry");
  const closeContactformModal = () => {
    setContactformOpen(false);
  }
  const closeEnquiryFormModal = () => {
    setEnquiryFormOpen(false);
  }

  let url_1 = ['']
  let url_2 = ['']
  if (page && page.Header_CTA_1_Link) {
    url_1 = GetURL(page.Header_CTA_1_Link.id)
  }
  if (page && page.Header_CTA_2_Link) {
    url_2 = GetURL(page.Header_CTA_2_Link.id)
  }


  const openForm = (formTitle, formName) => {
    if (formName === "Contact_Form") {
      setContactformOpen(true);
      setModalFormTitle(formTitle)
    }
  };
  return (
    <>
      {page && (page.CTA_1_Label || page.CTA_2_Label) && !bannerInView &&
        <>
          <div className={`sticky-footer-bottom d-lg-none`} ></div>
          <div className={`property-footer d-lg-none`} >
            <Container>

              <Row>
                <Col lg={12}>
                  <div className="feature-section sticky-footer">
                    <div className="footer-section-btn">
                      {/* <a href="javascript:void(0)" className="btn btn-primary d-md-flex" >Arrange a viewing <i className="icon-arrow"></i></a> */}

                      {page.CTA_1_Label && page.CTA_1_Form &&
                        <Button onClick={() => { openForm(page.CTA_1_Label, page.CTA_1_Form) }} className="btn btn-primary d-md-flex">{page.CTA_1_Label}<i className="icon-arrow"></i></Button>
                      }

                      {page.CTA_2_Label && page.CTA_2_Form &&
                        <Button onClick={() => { openForm(page.CTA_2_Label, page.CTA_2_Form) }} className="btn btn-primary d-md-flex">{page.CTA_2_Label}<i className="icon-arrow"></i></Button>
                      }

                      {page.CTA_1_Label && page.Header_CTA_1_Link && url_1[1] == 'Internal' ?
                        (url_1[0] === "about/careers") ?
                          <a href={`#career-form`} className="btn btn-primary d-md-flex">{page.CTA_1_Label}<i className="icon-arrow"></i></a>
                          : (path === "/property-services/mortgages/" && url_1[0] === "property-services/mortgages") ? <a href={`https://www.mortgageadvicebureau.com/mortgage-advisers/severn-mortgage-and-protect/meet-the-team`} target="_blank" className="btn btn-primary">{page.CTA_1_Label}<i className="icon-arrow"></i></a> :
                            <Link to={`/${url_1[0]}`} className="btn btn-primary d-md-flex">{page.CTA_1_Label}<i className="icon-arrow"></i></Link>
                        :
                        page.CTA_1_Label && page.Header_CTA_1_Link && url_1[1] == 'External' ?
                          <a href={`${url_1[0]}`} target="_blank" className="btn btn-primary d-md-flex">{page.CTA_1_Label}<i className="icon-arrow"></i></a>
                          : ''
                      }

                      {page.CTA_2_Label && page.Header_CTA_2_Link && url_2[1] == 'Internal' ?
                        <Link to={`/${url_2[0]}`} className="btn btn-primary">{page.CTA_2_Label}<i className="icon-arrow"></i></Link>
                        :
                        page.CTA_2_Label && page.Header_CTA_2_Link && url_2[1] == 'External' ?
                          <a href={`${url_2[0]}`} target="_blank" className="btn btn-primary d-md-flex">{page.CTA_2_Label}<i className="icon-arrow"></i></a>
                          : ''
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
              <ModalTiny.Header closeButton className="contact-close-btn">
                <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">{modalFormTitle}</h2></ModalTiny.Title>
              </ModalTiny.Header>
              <ModalTiny.Body className="event-section"><ContactForm title={modalFormTitle} /></ModalTiny.Body>
            </ModalTiny>
            <ModalTiny size="lg" show={modalEnquiryFormOpen} onHide={closeEnquiryFormModal} className="modal-form">
              <ModalTiny.Header closeButton className="contact-close-btn">
                <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Request a mortgage call back</h2></ModalTiny.Title>
              </ModalTiny.Header>
              <ModalTiny.Body className="event-section"><EnquiryForm title="Request a mortgage call back" /></ModalTiny.Body>
            </ModalTiny>
          </div>
        
        </>
      }

    </>
  )
}



export default StickyFooter